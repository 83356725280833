export async function login (email, password) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  const response = await fetch('https://cuestionario.bmivida.com/rest/v2/usuarios/login', {
    method: 'POST',
    body: formData
  })
  const data = await response.json()
  return data
}

export async function getGroups (idUser, token) {
  const response = await fetch(`https://cuestionario.bmivida.com/rest/v2/gruposEnfermedades/getAll?idUsuario=${idUser}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  const data = await response.json()
  return data
}

export async function getTree (groupId, idUser, token) {
  const response = await fetch(`https://cuestionario.bmivida.com/rest/v2/preguntas/getAllPathing?idPregunta=${groupId}&idUsuario=${idUser}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data.message)
  }
  return data
}
