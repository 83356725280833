import './App.css';
import Button from './components/Button';
import { useState, useEffect } from 'react';
import { getGroups, login } from './func';
import TreeContainer from './components/TreeContainer';

function App () {
  const [activeButton, setActiveButton] = useState();
  const [grupos, setGrupos] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [initialTree, setInitialTree] = useState({});

  function handleClick (id) {
    setActiveButton(id);
  }

  function handleLogin (e) {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    login(email, password).then(data => {
      console.log(data);
      if (data.token) {
        setIsAuthenticated(true);
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('idUser', data.id);
      } else {
        alert('Contraseña incorrecta');
      }
    })
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    console.log('Recuperando grupos...')
    getGroups(sessionStorage.getItem('idUser'), sessionStorage.getItem('token'))
      .then(data => {
        const grupos = data
        setGrupos(grupos)
        setActiveButton(grupos[0].id)
      })
      .catch(err => {
        setIsAuthenticated(false)
        window.sessionStorage.clear()
        console.error(err)
      })
  }, [isAuthenticated])

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const idUser = sessionStorage.getItem('idUser');
    if (!token || !idUser) {
      return;
    }
    setIsAuthenticated(true);
  }, [])

  if (!isAuthenticated) {
    return (
      <div className="App">
        <main>
          <h1>🌲 Generador de árboles Cuestionario BMI Vida</h1>
          <form onSubmit={handleLogin}>
            <p className="m-0 title">Login</p>
            <input
              name='email'
              type="text"
              placeholder="Email"
              className="input"
            />
            <input
              name='password'
              type="password"
              placeholder="Contraseña"
              className="input"
            />
            <button type="submit" style={{ padding: '14px 0', fontSize: '1.2rem' }}>Entrar</button>
          </form>
        </main>
      </div>
    );
  }
  return (
    <div className="App">
      <nav>
        {grupos.map((button) => (
          <Button onClick={() => handleClick(button.id)} active={activeButton === button.id} key={'button-' + button.id}>
            {button.nombre}
          </Button>
        ))}
      </nav>
      <main>
        <h1>🌲 Generador de árboles Cuestionario BMI Vida</h1>
        {/* <p >Grupo seleccionado: {grupos.find(grupo => grupo.id === activeButton).nombre}</p> */}
        <p style={{ opacity: 0.65 }}>Selecciona un grupo de enfermedades para generar el árbol</p>
        <TreeContainer activeGroupId={activeButton} initialTree={initialTree} setInitialTree={setInitialTree} setIsAuthenticated={setIsAuthenticated} />
      </main>
    </div>
  );
}

export default App;
