import { useState } from "react"

export default function Button ({ children, onClick, active }) {
  
  return <button
    onClick={onClick}
    style={{
      backgroundColor: active ? '#f0f0f0' : ''
    }}
  >
    {children}
  </button>
}