import Tree from 'react-d3-tree';
import { useEffect } from 'react';
import { getTree } from '../func';
import { useState } from 'react';

export default function TreeContainer ({ activeGroupId, initialTree, setInitialTree, setIsAuthenticated }) {
  const separation = { nonSiblings: 2, siblings: 6 }
  const [isLoading, setIsLoading] = useState(false);
  const config = {
    orientation: 'vertical',
    node: {
      color: "lightgreen",
      size: 120,
      highlightStrokeColor: "blue"
    },
  }

  useEffect(() => {
    if (!activeGroupId) {
      return;
    }
    setIsLoading(true);
    const token = sessionStorage.getItem('token');
    const idUser = sessionStorage.getItem('idUser');
    getTree(activeGroupId, idUser, token)
      .then(data => {
        if (!activeGroupId) {
          return;
        }
        // Función para procesar el árbol recursivamente
        const processTree = (node) => {
          // Copia del nodo para no mutar el original
          const newNode = { ...node };
          switch (node.attributes?.resultado) {
            case "1":
              newNode.name = `${node.name} -> Suscripción 📞`;
              break;
            case "2":
              newNode.name = `${node.name} -> Rechazado 🚫`;
              break;
            case "3":
              newNode.name = `${node.name} -> Aprobado ✅`;
              break;
            default:
              break;
          }
          // Procesar hijos recursivamente si existen
          if (node.children) {
            newNode.children = node.children.map(child => processTree(child));
          }
          return newNode;
        };
        // Procesar el árbol y actualizar el estado
        const processedData = processTree(data);
        setInitialTree(processedData);
      })
      .catch(error => {
        console.error('Error al obtener el árbol:', error);
        setIsAuthenticated(false);
        window.sessionStorage.clear();
      })
      .finally(() => {
        setIsLoading(false);
      })

  }, [activeGroupId, setInitialTree, setIsAuthenticated]);

  return (
    isLoading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <div className="spinner" style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #3498db',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite',
          WebkitAnimation: 'spin 1s linear infinite'
        }}>
        </div>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
            @-webkit-keyframes spin {
              0% { -webkit-transform: rotate(0deg); }
              100% { -webkit-transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    ) : (
      // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
      <div id="tree-container" style={{ width: '100%', height: '97%' }}>
        <Tree data={initialTree} orientation={'vertical'} separation={separation} config={config} pathFunc={'step'} />
      </div>
    )
  );
}
